import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1c119fe2&scoped=true"
import script from "./setup.js?vue&type=script&lang=js&external"
export * from "./setup.js?vue&type=script&lang=js&external"
import style0 from "./style.scss?vue&type=style&index=0&id=1c119fe2&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c119fe2",
  null
  
)

export default component.exports